import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import isWebview from 'is-ua-webview';

@Injectable()
export class GtmAnnouncerService {
    private eventAnnouncedSource = new Subject<any>();
    
    eventAnnounced$ = this.eventAnnouncedSource.asObservable();

    constructor() {        
        this.eventAnnounced$.subscribe(this.announceEvent.bind(this));
    }  

    public announceEvent(requestEvent: any): void {        
        const dataLayer = (window as any).dataLayer;
        if (dataLayer && !isWebview(navigator.userAgent)) {
            dataLayer.push(requestEvent);
        }
    }
}