import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { catchError, from, mergeMap, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ICompanyConfig, IConfig, Site } from "../models";
import { AuthService } from './auth.service';

@Injectable()
export class ConfigService {
    public config: IConfig;
    public company: ICompanyConfig;
    public siteConfig: Site = {
        banners: [],
        categories: [],
        features: [],
        siteBackgroundSearchButtonColor: "#999999",
        siteBackgroundSearchFormColor: "#999999",
        siteSearchButtonText: "Buscar Passagens",
        tripBanners: []
    };
    private authService: AuthService;


    public constructor(
        private httpClient: HttpClient,
    ) {
        this.authService = new AuthService(this);
    }

    public load(): Promise<void> {
        const companyConfig = `./config/${environment.comapany}/${environment.production ? 'config_prod' : 'config'}.json`;
        return this.httpClient.get(companyConfig)
            .toPromise()
            .then(data => {
                this.config = <any>data;
            });
    }

    public loadCompany(): Promise<void> {
        let company = `/${environment.comapany}`
        if (window.location.pathname.includes('/sampaio')) {
            company = '/sampaio'
        } else if (window.location.pathname.includes('/realexpresso')) {
            company = '/real-rapido'
        } else if (window.location.pathname.includes('/util')) {
            company = '/util'
        }


        const companyData = `./config${company}${environment.production ? company + '_prod' : company}.json`;
        return this.httpClient.get(companyData)
            .toPromise()
            .then(data => {
                this.company = <any>data;
            }).catch((err) => {
                // simple logging, but you can do a lot more, see below
                console.error('An error occurred:', err.error);
              });
    }

    private getHeaders(renewToken = false): Observable<HttpHeaders> {
        let result = new HttpHeaders();
        return from(this.authService.getToken(renewToken).then(authToken => {
            result = result.append('Authorization', `Bearer ${authToken}`);
            result = result.append('content-type', 'application/json');
            result = result.append('IdentifierWorkstation', this.company.identifierWorkstation);
            result = result.append('ProductId', this.config.productId);
            result = result.append('IdUser', this.company.idUser.toString());
            return result;
        }));
    }

    public loadSiteConfig(): void { //Promise<void> {
        let headers = new HttpHeaders();
        headers = headers.append('Cache-Control', 'no-cache, no-store, must-revalidate');
        headers = headers.append('Pragma', 'no-cache');
        headers = headers.append('Expires', '0');
        headers = headers.append('content-type', 'application/json');
        headers = headers.append('IdentifierWorkstation', this.company.identifierWorkstation);
        headers = headers.append('ProductId', this.config.productId);
        headers = headers.append('IdUser', this.company.idUser.toString());


        // if (environment.production) {
        //     const staticFile = this.config.configLocation + "/sites/" + this.company.idTenant.toString() + '.json?version=' + `${Math.random()}`;
        //     return this.httpClient.get<Site>(staticFile, { headers: headers })
        //         .toPromise()
        //         .then(siteConfig => {
        //             this.siteConfig = siteConfig;
        //         })
        //         .catch(err => {
        //         });
        // } else {
        const coreApiUrl = `${this.config.gatewayApiUrl}/app/core/site/configuration/${this.company.idTenant.toString()}`;
        this.getHeaders().pipe(mergeMap((headers) => {
            return this.httpClient
                .get<Site>(
                    coreApiUrl,
                    {
                        headers: headers
                    });
        }))
            .pipe(catchError(error => {

                if (error.status == 401) {
                    return this.getHeaders(true).pipe(mergeMap((headers) => {
                        return this.httpClient
                            .get<Site>(
                                coreApiUrl,
                                {
                                    headers: headers
                                });
                    }));
                }

                return throwError(error);
            })).subscribe((value) => {
                this.siteConfig = value;
            });
        // }

    }

    private toCamel(o: any): any {
        let newO: { [key: string]: any };
        let origKey;
        let newKey: string;
        let value;

        if (o instanceof Array) {
            return o.map((value) => {
                if (typeof value === "object") {
                    value = this.toCamel(value)
                }
                return value
            })
        } else {
            newO = {}
            for (origKey in o) {
                if (o.hasOwnProperty(origKey)) {
                    newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                    value = o[origKey]
                    if (value instanceof Array || (value !== null && value.constructor === Object)) {
                        value = this.toCamel(value)
                    }
                    newO[newKey] = value
                }
            }
        }
        return newO
    }
}