<div class="container" *ngIf="displayCategories && displayCategories.length > 0">
    <div class="row">
        <div class="col-md-12">
            <div *ngIf="isMobile" class="owl-carousel-filters select">
                <select class="owl-carousel-filters-select" (change)="filterBanners($event.target.value)">
                    <option *ngFor="let category of displayCategories" [value]="category.idSiteBannerCategory">
                        {{category.name}}
                    </option>
                </select>
            </div>
            <div *ngIf="!isMobile" class="owl-carousel-filters tags">
                <div *ngFor="let category of displayCategories" (click)="filterBanners(category.idSiteBannerCategory)"
                     [ngClass]="category.idSiteBannerCategory === selectedCategory.idSiteBannerCategory ? 'owl-carousel-filter active' : 'owl-carousel-filter'">
                    {{category.name}}
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <owl-carousel-o *ngIf="displayBanners && displayBanners.length > 0 && !isMobile" [options]="desktopOptions" (dragging)="onCarouselDrag($event.dragging)">
                <ng-container *ngFor="let banner of displayBanners">
                    <ng-template carouselSlide [id]="banner.idSiteBannerCategory">
                        <a href="#" (click)="openLink(banner.link)">
                            <img *ngIf="!isMobile" style="max-width: 350px;" [src]="imageHelper.getImageLocation(banner.imageToken)" [alt]="banner.altText" [title]="banner.altText" class="img-slide" />
                        </a>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
            <owl-carousel-o *ngIf="displayBanners && displayBanners.length > 0 && isMobile" [options]="mobileOption" (dragging)="onCarouselDrag($event.dragging)">
                <ng-container *ngFor="let banner of displayBanners">
                    <ng-template carouselSlide [id]="banner.idSiteBannerCategory">
                        <a href="#" (click)="openLink(banner.link)">
                            <img *ngIf="isMobile" style="max-width: 200px;" [src]="imageHelper.getImageLocation(banner.imageToken)" [alt]="banner.altText" [title]="banner.altText" class="img-slide" />
                        </a>
                    </ng-template>
                </ng-container>
            </owl-carousel-o>
        </div>
    </div>
</div>
