import { Component, ViewEncapsulation, Input, ChangeDetectionStrategy, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-announcement',
    templateUrl: './announcement.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AnnouncementComponent implements OnChanges {
    public imgPath = "../../../../../assets/site-campaign/"
    public title: string = (() => {
        if (window.location.pathname.includes("/sampaio")) {
            return "Sampaio agora é Guanabara!";
        }
        else if (window.location.pathname.includes("/realexpresso")) {
            return "Real e Rápido agora são Guanabara!";
        }
        else if (window.location.pathname.includes("/util")) {
            return "Util agora é Guanabara";
        }
    })();

    public company: string = (() => {
        if (window.location.pathname.includes("/sampaio")) {
            return "Sampaio";
        }
        else if (window.location.pathname.includes("/realexpresso")) {
            return "Real Expresso";
        }
        else if (window.location.pathname.includes("/util")) {
            return "Util";
        }
    })();

    public img: string = (() => {
        if (window.location.pathname.includes("/sampaio")) {
            return this.imgPath + 'sampaio';
        }
        else if (window.location.pathname.includes("/realexpresso")) {
            return this.imgPath + 'realexpresso';
        }
        else if (window.location.pathname.includes("/util")) {
            return this.imgPath + 'util';
        }
    })();

    constructor(
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {

    }

}
