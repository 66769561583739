export * from './base.api';
export * from './base.service';
export * from './config.service';
export * from './company.service';
export * from './initialize.service';
export * from './document.service';
export * from './customer-auth.service';
export * from './logo.service';
export * from './storage.service';
export * from './location.service';
export * from './trip.service';
export * from './gtm-announcer.service';
export * from './passenger-classification.service';
export * from './salesforce.service';

import { InitializerService, InitializerProvider } from './initialize.service';
export const ALL_INITIALIZERS = [
    InitializerService,
    InitializerProvider
];

import { ConfigService } from './config.service';
import { CompanyService } from './company.service';
import { DocumentService } from './document.service';
import { BaseService } from './base.service';
import { CustomerAuthService } from './customer-auth.service';
import { LogoService } from './logo.service';
import { StorageService } from './storage.service';
import { LocationService } from './location.service';
import { TripService } from './trip.service';
import { GtmAnnouncerService } from './gtm-announcer.service';
import { PassengerClassificationService } from './passenger-classification.service';
import { AuthService } from './auth.service';
import { SalesforceService } from './salesforce.service';
import { DataConfigurationService } from 'src/app/core/services/data-configuration.service';
export const ALL_MAIN_SERVICES = [
    ConfigService,
    AuthService,
    CompanyService,
    DocumentService,
    BaseService,
    CustomerAuthService,
    LogoService,
    StorageService,
    LocationService,
    TripService,
    DataConfigurationService,
    GtmAnnouncerService,
    PassengerClassificationService,
    SalesforceService
]
