import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { ApplicationRef, DoBootstrap, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BrowserStateInterceptor, CompanyService, ConfigService, Customer, CustomerAuthService, Language, MainModule, SeoHelper, StorageHelper, StorageService } from './main';
import { LazyLoadImageModule } from 'ng-lazyload-image'; 
import { BaseService } from '../app/main/services/base.service';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';

declare const Konduto: any;
registerLocaleData(localePt);
export function HttpLoaderFactory(http: HttpClient) { return new TranslateHttpLoader(http); }

@NgModule({
    imports: [
        MatButtonModule,
        MatDialogModule,
        LazyLoadImageModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        BrowserAnimationsModule,
        RouterModule.forRoot([], { scrollPositionRestoration: 'enabled', onSameUrlNavigation: 'reload', enableTracing: false }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        MainModule.forRoot()
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: "pt"
        },
        {
            provide: APP_BASE_HREF, useValue: '/'
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BrowserStateInterceptor,
            multi: true
        }
    ]
})
export class AppModule implements DoBootstrap {
    gtmAnnouncementsSubscription: Subscription;
    announcerSubscription: Subscription;

    public constructor(
        private router: Router,
        private storage: StorageService,
        private translateService: TranslateService,
        private configService: ConfigService,
        private companyService: CompanyService,
        private seoHelper: SeoHelper,
        private activatedRoute: ActivatedRoute,
        private customerAuthService: CustomerAuthService,
        private storageHelper: StorageHelper,
        private base: BaseService,
    ) {
    }

    public ngDoBootstrap(appRef: ApplicationRef): void {
        let key = Object.keys(Language).find(key => Language[key] === Language[this.configService.company.lang]);
        this.translateService.setDefaultLang(key);
        this.customerAuthService.loadBaseApi();
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params.o && params.h) {
                this.customerAuthService.login(atob(params.o), atob(params.h), this.base.configService.company.origin).subscribe((customer: Customer) => {
                    if (customer.idCustomer === 0) {
                        return;
                    }

                    if (this.configService.company.enableKonduto && (typeof (Konduto) !== "undefined") &&
                        (typeof (Konduto.sendEvent) !== "undefined")) {
                        Konduto.setCustomerID(customer.idCustomer);
                    }
                    this.storage.setLoggedCustomer(customer);
                })
            }

            if (params.logout) {
                this.storage.setLoggedCustomer(null);
            }
        });

        this.router.events.subscribe(event => {
            // Checking page, if home, expands WhatsApp widget, if not, collapse it.
            var is_root = location.pathname == "/"; //Equals true if we're at the root
            var wts = <HTMLInputElement>document.getElementById("wts-widget");

            if(wts !== null){
                if (!is_root)
                    wts.classList.add("wts-widget-collapse");
                else
                    wts.classList.remove("wts-widget-collapse");
            }            

            if (event instanceof NavigationStart) {
                this.seoHelper.setMetadata(event.url);
                this.seoHelper.setTitle(event.url);
            }

            if (event instanceof NavigationEnd) {
                if (!event.url.includes('onibus')
                    && !event.url.includes('remarcacao')
                    && !event.url.includes('cancelamento')
                    && !event.url.includes('termos')
                    && !event.url.includes('privacidade')
                    && !event.url.includes('session')) {
                    this.storage.clear();
                }

                if (environment.production && this.configService.config.useSubdomainRedirect) {
                    let currentUrl = event.url.includes('?o=') || event.url.includes('?logout=1') ? '/' : event.url;
                    if (currentUrl === '/') {
                        const url = window.location.host;
                        if (url.includes('ecommerce')) {
                            if (url.includes('http://')) {
                                window.location.href = location.href.replace('http://ecommerce.', 'https://');
                            } else {
                                window.location.href = location.href.replace('https://ecommerce.', 'https://');
                            }
                        }
                    } else {
                        const url = window.location.host;
                        if (!url.includes('ecommerce')) {
                            if (url.includes('http://')) {
                                window.location.href = location.href.replace('http://', 'https://ecommerce.');
                            } else {
                                window.location.href = location.href.replace('https://', 'https://ecommerce.');
                            }
                        }
                    }
                }
            }
        });

        appRef.bootstrap(this.companyService.company.getIndex());

    }
}