<div class="passenger-types-select-container">
    <div class="input-location-container">
        <input id="typeahead-focus" type="text" class="input-location" (click)="isOpen = !isOpen" [value]="totalQtdStr"
            readonly placeholder="{{ 'search.passengerType' | translate }}" />
    </div>

    <div tabindex="0" class="passenger-types-select" [class.is-open]="isOpen">
        <div *ngFor="let item of groupedPassengerTypes | keyvalue">
            <div class="passenger-types-select-item" [class.disabled]="item.value.selectedQtd == 0"
                [class.deficient]="item.value.passengerClassification.classificationType == PassengerClassificationType.Deficient && item.value.selectedQtd > 0">
                <div class="title-container">
                    <div class="passenger-type-title">{{ item.value.passengerClassification.name }}</div>
                    <div class="passenger-type-subtitle">{{ item.value.passengerClassification.remarks }}</div>
                </div>
                <div class="passenger-item-actions">
                    <button class="btn btn-primary" [disabled]="item.value.selectedQtd < 2 && item.value.passengerClassification.classificationType != PassengerClassificationType.Child
                                                                || item.value.selectedQtd < 1 && item.value.passengerClassification.classificationType == PassengerClassificationType.Child
                                                                || item.value.selectedQtd < 3 && item.value.passengerClassification.classificationType == PassengerClassificationType.Deficient" (click)="decreasePassengerType(item.value)">
                        <i class="fas fa-minus"></i>
                    </button>
                    <div *ngIf="item.value.passengerClassification.classificationType != PassengerClassificationType.Deficient" class="quantity">{{item.value.selectedQtd}}</div>
                    <div *ngIf="item.value.passengerClassification.classificationType == PassengerClassificationType.Deficient" class="quantity">{{item.value.selectedQtd > 0 ? 1 : 0 }}</div>
                    <button class="btn btn-primary" [disabled]="getMaxPassengerQuantity(item.value.passengerClassification.classificationType) <= item.value.selectedQtd 
                                                                    || (item.value.passengerClassification.classificationType == PassengerClassificationType.Deficient && item.value.selectedQtd > 0)" (click)="increasePassengerType(item.value)">
                        <i class="fas fa-plus"></i>
                    </button>

                </div>
            </div>
            <div class="passenger-types-select-item companion"
                *ngIf="item.value.passengerClassification.classificationType == PassengerClassificationType.Deficient && item.value.selectedQtd > 0"
                [class.disabled]="item.value.selectedQtd > 2">
                <div class="title-container">
                    <div class="passenger-type-title">ACOMPANHANTE</div>
                    <!-- <div class="passenger-type-subtitle">{{ item.value.passengerClassification.remarks }}</div> -->
                </div>
                <div class="passenger-item-actions">
                    <button class="btn btn-primary" [disabled]="item.value.selectedQtd < 2" (click)="decreasePassengerType(item.value,true)">
                        <i class="fas fa-minus"></i>
                    </button>
                    <div class="quantity">{{item.value.selectedQtd > 1 ? 1 : 0 }}</div>
                    <button class="btn btn-primary" [disabled]="getMaxPassengerQuantity(item.value.passengerClassification.classificationType) <= item.value.selectedQtd" (click)="increasePassengerType(item.value,true)">
                        <i class="fas fa-plus"></i>
                    </button>
                </div>
            </div>
        </div>

        <!-- <span class="passenger-types-alert my-2 fs-12 text-danger text-center" [class.d-none]="!error">Não é possível combinar os dois tipos de passagem selecionados.</span> -->
        <span class="passenger-types-alert my-2 fs-12 text-center" [class.d-none]="error">Somente algumas combinações de passagens estão disponíveis no momento.</span>
        
        <button (click)="isOpen = false" class="btn btn-primary">Confirmar Seleção</button>
    </div>
</div>