import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { BrowserHelper, ImageHelper } from '../../../helpers';
import { Banner, Category } from '../../../models';
import isWebview from 'is-ua-webview';

@Component({
    selector: 'app-owl-carousel',
    templateUrl: './owl-carousel.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class OwlCarouselComponent implements OnChanges {
    @Input('categories') categories: Category[];
    displayCategories: Category[] = [];
    selectedCategory: Category;
    banners: Banner[] = [];
    displayBanners: Banner[] = [];
    isMobile: boolean = false;
    currentDom: any;
    isDragging: boolean = false;
    desktopOptions: OwlOptions = {
        items: 3,
        loop: false,
        dots: true,
        nav: false,
        mouseDrag: true,
        touchDrag: true,
        pullDrag: true,
        margin: 20
    }
    mobileOption: OwlOptions = {
        items: 1,
        mouseDrag: true,
        dots: true,
        touchDrag: true,
        pullDrag: true,
        responsive: {
            0: { items: 1 },
            768: { items: 2 },
            1920: {
                items: 3
            },
        },
        stagePadding: 0,
        center: true,
        margin: -100
    }

    constructor(public imageHelper: ImageHelper,
        public browserHelper: BrowserHelper) {
        this.isMobile = browserHelper.runningOnMobile();
        this.currentDom = window;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (this.categories && this.categories.length > 0) {
            this.displayCategories = this.categories.filter(c => c.banners.length > 0);

            if (this.displayCategories.length === 0) {
                return;
            }

            const defaultCateg = this.displayCategories.find(c => c.isDefault);
            if (defaultCateg) {
                this.displayBanners = defaultCateg.banners;
                this.selectedCategory = defaultCateg;
            } else {
                const someCateg = this.displayCategories[0];
                this.displayBanners = someCateg.banners;
                this.selectedCategory = someCateg;
            }
        }
    }

    filterBanners = (idSiteBannerCategory: number) => {
        this.selectedCategory = this.categories.find(c => c.idSiteBannerCategory == idSiteBannerCategory);
        if (!this.selectedCategory) {
            return;
        }
        this.displayBanners = this.selectedCategory.banners;
    }

    getOptions = (): OwlOptions => {
        if (this.isMobile) {
            let customOptions: OwlOptions = {
                items: 1,
                mouseDrag: true,
                dots: true,
                touchDrag: true,
                pullDrag: true,
                responsive: {
                    0: { items: 1 },
                    768: { items: 2 },
                    1920: {
                        items: 3
                    },
                },
                stagePadding: 0,
                center: true,
                margin: -100
            };

            return customOptions;
        } else {
            let customOptions: OwlOptions = {
                items: 3,
                loop: false,
                dots: true,
                nav: false,
                mouseDrag: true,
                touchDrag: true,
                pullDrag: true,
                margin: 20
            };

            return customOptions;
        }
    }

    onCarouselDrag(dragging: boolean): void {
        setTimeout(() => {
            this.isDragging = dragging;
        }, 10)
    }

    openLink = (link: string): void => {
        if (this.isDragging) {
            return;
        }


        if (!link) {
            return;
        }

        if (isWebview(navigator.userAgent)) {
            window.location.href = link;
        } else {
            window.open(link, '_blank');
        }
    }
}
