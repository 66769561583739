<div [style]="!showComponent ? 'display:none' : null">
    <style>
        @media only screen and (min-width : 320px) and (max-width :1024px) {
            .container-black-friday {
                margin-top: 2% !important;
                min-height: 50px !important;
                width: 100vw;
            }

            .box-black-friday {
                width: 100vw !important;
                max-height: 30px !important;
                font-size: 10px !important;
                padding: 5px;
            }

            .icon-timer {
                font-size: 15px !important;
            }
        }

        @media (min-width: 1280px) and (max-width: 2500px) {
            
            .container-black-friday {
                margin-top: 1% !important;
                min-height: 50px !important;
            }

            .box-black-friday {
                width: 47vw !important;
                max-height: 60px !important;
            }
        }

        @media (min-width: 2500px) {
            .container-black-friday {
                margin-top: 9.5% !important;
            }
        }


        @media (min-width: 1025px) and (max-width: 1280px) {
            .container-black-friday {
                background-color: blue!important;
                margin-top: 0px !important;
            }
        }

        @media (min-width: 768px) and (max-width: 1024px) {
            .container-black-friday {
                background-color: orange!important;
                margin-top: 0px !important;
            }

        }

        .container-black-friday {
            display: flex;
            justify-content: center;
            background-color: #831D34;
            min-height: 100px;
            padding: 20px;
            margin-top: 9.4%;
        }

        .box-black-friday {
            text-rendering: optimizeLegibility;
            border-radius: 15.93px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            font-weight: 700;
            width: 30vw;
            height: 80%;
            padding: 21px;
            font-size: 18px;
            color: rgba(148, 31, 55, 1);
            background-color: rgba(245, 187, 27, 1);
        }

        .icon-timer {
            font-size: 18px;
        }
    </style>
    <main class="container-black-friday">
        <div class="box-black-friday">
            <div>
                <span class="icon-timer">⏰</span> FALTAM
                <span style="color: rgba(55, 45, 47, 1); font-weight: bold;">{{countdownText}}</span> PARA COMPRAR NA BLACK FRIDAY
            </div>
        </div>
    </main>
</div>