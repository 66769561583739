import { Component, Input, OnInit, AfterViewInit, ElementRef } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import { fromLonLat } from 'ol/proj';
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import Style from 'ol/style/Style';
import Icon from 'ol/style/Icon';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';

@Component({
  selector: 'app-map-departure-component',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.css'] // Adicione o caminho para o seu arquivo de estilo CSS
})
export class MapGuanabaraDepartureComponent implements OnInit, AfterViewInit {
  @Input() address: string;
  @Input() modalStatus: boolean;

  map: Map;
  private modalElement: HTMLElement;
  coordinatesFound: boolean;

  constructor(private elRef: ElementRef) {
   
   }

  ngOnInit() {
    this.modalElement = this.elRef.nativeElement.closest('.trips-modal-wrapper');
    this.initializeMap();
  }

  ngAfterViewInit() {
    // Configurando o observador de mutação para monitorar mudanças no atributo style do elemento do modal
    const observer = new MutationObserver(() => {
      if (this.modalStatus) {
        this.updateMap();
      }
    });

    // Iniciando a observação das mudanças no atributo style do elemento do modal
    observer.observe(this.modalElement, { attributes: true, attributeFilter: ['style'] });
  }

  
  private initializeMap() {
    if (this.modalStatus && this.address) {
      this.getCoordinatesFromAddress(this.address).then((coordinates: any) => {
        if (this.coordinatesFound) {
          
          const rome = new Feature({
            geometry: new Point(fromLonLat(coordinates)),
          });
      
          rome.setStyle(
            new Style({
              image: new Icon({
                color: '#BADA55',
                crossOrigin: 'anonymous',
                src: 'https://icon-library.com/images/gps-pin-icon/gps-pin-icon-3.jpg',
              }),
            })
          );
      
          const vectorSource = new VectorSource({
            features: [rome],
          });
      
          const vectorLayer = new VectorLayer({
            source: vectorSource,
          });
          this.map = new Map({
            layers: [new TileLayer({ source: new OSM() }), vectorLayer],
            target: 'map-departure',
            view: new View({
              center: fromLonLat(coordinates),
              zoom: 16,
              maxZoom: 18,
            }),
            controls: [],
          });
          this.toggleMapVisibility(true);
        } else {
          this.toggleMapVisibility(false);
        }
      });
    } else {
      this.toggleMapVisibility(false);
    }
  }

  
  private updateMap() {
    if (this.modalStatus && this.map) {
      if (this.address) {
        this.getCoordinatesFromAddress(this.address).then((coordinates: any) => {
          if (this.coordinatesFound) {
            this.map.getView().setCenter(fromLonLat(coordinates));
            this.map.getView().setZoom(20);
            
            this.toggleMapVisibility(true);
          } else {
            this.toggleMapVisibility(false);
          }
        });
      } else {
        this.toggleMapVisibility(false);
      }
    } else {
      this.initializeMap();
    }
  }

  private toggleMapVisibility(visible: boolean) {
    const mapElement = document.getElementById('map-departure');
    if (mapElement) {
      if (visible) {
        mapElement.classList.remove('hidden-map');
      } else {
        mapElement.classList.add('hidden-map');
      }
    }
  }

  private async getCoordinatesFromAddress(address: string): Promise<number[]> {
    const newAddress = address.split('-')[0].trim()
    const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${newAddress}`);
    const data = await response.json();

    if (data && data.length > 0) {
      const firstResult = data[0];
      this.coordinatesFound = true;
      return [parseFloat(firstResult.lon), parseFloat(firstResult.lat)];
    } else {
      const mapElement = document.getElementById('map-departure');
      mapElement.classList.add('hidden-map');
      this.map.setTarget(null);
      this.map = null;
      this.coordinatesFound = false;
      console.log('Não foi possível obter as coordenadas para o endereço');
      return [0, 0];
    }
  }
}
