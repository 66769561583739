export class SalesforceRequest {
    productId: string;
    productName: string;
    productUrl: string;
    companyName: string;
    price: number;
    productImageLink: string;

    constructor(){
        this.productImageLink = "https://image.support.gogipsy.com/lib/fe2e117171640475731c71/m/1/bussGuanab.png";
        this.companyName = "Guanabara";
    }
}