<div class="row">
    <div class="main-routes" style="margin-bottom: 5px!important;">
        <h2 class="main-routes-title" style="margin-bottom: 0px;">
            {{ title }}
        </h2>
    </div>
    <div class="row" *ngIf="company==='Sampaio'">
        <div class="col" style="flex: 1;">
            <div style="width:100%; overflow-x: hidden; font-size: 16px;">
                <style>
                    p {
                        font-weight: 400;
                    }
                </style>
                <p>
                    Você já conhece toda a tradição e a qualidade das viagens de ônibus com a Sampaio, não é mesmo? Mas
                    o
                    que já era bom ficou ainda melhor! A Sampaio agora faz parte da família Guanabara, a melhor empresa
                    de
                    ônibus do Brasil, de acordo com o Prêmio Maiores & Melhores do Transporte, da OTM Editora -
                    2022.
                </p>

                <p>
                    O Grupo Guanabara possui mais de 60 anos de tradição no mercado rodoviário brasileiro, com uma
                    história
                    de credibilidade em expansão. Além das melhores viagens do Brasil, a Guanabara tem como marca
                    registrada
                    o compromisso com a segurança, a inovação na tecnologia, a responsabilidade ambiental e claro, a
                    satisfação dos nossos viajantes do início ao fim. Com uma frota de mais de 400 ônibus, o conforto e
                    a
                    qualidade estão garantidos, com carregador USB, conexão wi-fi e ar-condicionado. Tudo pra você ter a
                    melhor experiência até o seu destino.
                </p>

                <p>
                    Mas o que acontece quando duas grandes empresas de ônibus se juntam? Você sai ganhando! A Sampaio
                    junto
                    com a Guanabara oferece mais de 2000 destinos pelo Brasil. Isso tudo com uma frota novinha e
                    moderna,
                    para viajar com ainda mais segurança. Sem contar a facilidade de comprar passagens no conforto da
                    sua
                    casa, pelo site ou aplicativo. É só acessar <a
                        href="www.viajeguanabara.com.br">www.viajeguanabara.com.br</a> ou baixar o aplicativo Viaje
                    Guanabara. É rápido, prático e seguro.<br>
                </p>
                <p>
                    Curtiu a novidade? Pega o Guanabara e vem!
                </p>
            </div>
        </div>
        <div class="col" style="flex: 1;">
            <div class="main-routes" style="display: flex; justify-content: center; text-align: center;">
                <img src="../../../../../assets/site-campaign/sampaio.png" alt="onibus guanabara" class="imageBus">
            </div>
        </div>
    </div>

    <div class="row" *ngIf="company==='Real Expresso'">
        <div class="col" style="flex: 1;">
            <div style="width:100%; overflow-x: hidden; font-size: 16px;">
                <style>
                    p {
                        font-weight: 400;
                    }
                </style>
                <p>
                    Sim, a Real Expresso e Rápido Federal reuniram toda sua história e tradição em viagens para se
                    juntar com a melhor empresa de ônibus do Brasil, a Guanabara. E isso não é a gente que está falando,
                    não. Esse título foi conquistado pela Guanabara em 2022, no Prêmio Maiores & Melhores do Transporte,
                    da OTM Editora.
                </p>

                <p>
                    O Grupo Guanabara possui mais de 60 anos de tradição no mercado rodoviário brasileiro, com uma
                    história de credibilidade em expansão. Além das melhores viagens do Brasil, a Guanabara tem como
                    marca registrada o compromisso com a segurança, a inovação na tecnologia, a responsabilidade
                    ambiental e claro, a satisfação dos nossos viajantes do início ao fim. Com uma frota de mais de 400
                    ônibus, o conforto e a qualidade estão garantidos, com carregador USB, conexão wi-fi e
                    ar-condicionado. Tudo pra você ter a melhor experiência até o seu destino.
                </p>

                <p>
                    Além de toda a qualidade e segurança que você já conhece, a Real Expresso e Rápido Federal junto com
                    a Guanabara possuem mais de 2.000 destinos por todo esse Brasil, além de uma frota novinha e
                    moderna. Sem contar a facilidade de comprar passagens no conforto da sua casa, pelo site ou
                    aplicativo. É só acessar <a href="www.viajeguanabara.com.br">www.viajeguanabara.com.br</a> ou baixar
                    o aplicativo Viaje Guanabara. É rápido,
                    prático e seguro.<br>
                </p>
                <p>
                    Curtiu a novidade? Pega o Guanabara e vem!
                </p>
            </div>
        </div>
        <div class="col" style="flex: 1;">
            <div class="main-routes" style="display: flex; justify-content: center; text-align: center;">
                <img src="../../../../../assets/site-campaign/bus-real.webp" alt="onibus guanabara" class="imageBus">
            </div>
        </div>
    </div>
    <div class="row" *ngIf="company==='Util'">
        <div class="col" style="flex: 1;">
            <div style="width:100%; overflow-x: hidden; font-size: 16px;">
                <style>
                    p {
                        font-weight: 400;
                    }
                </style>
                <p>
                    Você já sabe que a UTIL adora inovar e já promoveu mudanças ousadas nas viagens de ônibus desse
                    país. Não é à toa que a UTIL agora faz parte da família Guanabara, a melhor empresa de ônibus do
                    Brasil, título conquistado no Prêmio Maiores & Melhores do Transporte, da OTM Editora - 2022.
                </p>

                <p>
                    O Grupo Guanabara possui mais de 60 anos de tradição no mercado rodoviário brasileiro, com uma
                    história de credibilidade em expansão. Além das melhores viagens do Brasil, a Guanabara tem como
                    marca registrada o compromisso com a segurança, a inovação na tecnologia, a responsabilidade
                    ambiental e claro, a satisfação dos nossos viajantes do início ao fim. Com uma frota de mais de 400
                    ônibus, o conforto e a qualidade estão garantidos, com carregador USB, conexão wi-fi e
                    ar-condicionado. Tudo pra você ter a melhor experiência até o seu destino.
                </p>

                <p>
                    Mudamos pra melhor! Agora, a UTIL junto com a Guanabara oferece mais de 2.000 destinos para você
                    poder viajar muito por esse Brasil, com uma frota inovadora e moderna. Sem contar a facilidade de
                    comprar passagens no conforto da sua casa, pelo site ou aplicativo. É só acessar
                    <a href="www.viajeguanabara.com.br">www.viajeguanabara.com.br</a> ou baixar o aplicativo Viaje Guanabara. É rápido, prático e seguro.<br>
                </p>
                <p>
                    Curtiu a novidade? Pega o Guanabara e vem!
                </p>
            </div>
        </div>
        <div class="col" style="flex: 1;">
            <div class="main-routes" style="display: flex; justify-content: center; text-align: center;">
                <img src="../../../../../assets/site-campaign/util.webp" alt="onibus guanabara" class="imageBus">
            </div>
        </div>
    </div>

</div>

<style>
    p {
        hyphens: auto;
    }

    .imageBus {
        border-radius: 5px;
    }

    @media (max-width: 768px) {
        .col {
            flex-direction: column;
        }

        .imageBus {
            width: 90vw;
        }
    }

    @media (min-width: 768px) {
        .imageBus {
            max-width: 100%
        }
    }
</style>