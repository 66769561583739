import { Compiler, Injectable, Injector } from "@angular/core";
import { environment } from "src/environments/environment";
import { ICompany } from "../models";
import { ConfigService } from "./config.service";

@Injectable()
export class CompanyService {

    public company: ICompany;

    public constructor(
        private configService: ConfigService,
        private compiler: Compiler,
        private injector: Injector
    ) {
        if (environment.production && location.protocol === 'http:') {
            window.location.href = location.href.replace('http', 'https');
        }
    }

    public load(): Promise<any> {
        switch (this.configService.config.company) {
            case "guanabara": return this.loadGuanabara();
            // case "sampaio": return this.loadSampaio();
            // case "realexpresso": return this.loadRealExpresso();
            // case "util": return this.loadUtil();

            default: throw `Invalid company '${this.configService.config.company}!!!`;
        }
    }

    private async loadGuanabara(): Promise<any> {
        const mod = await import('src/app/company/guanabara/main/guanabara-main.module');
        const mod_1 = await this.compiler.compileModuleAsync(mod.GuanabaraMainModule);
        this.company = mod_1.create(this.injector).instance;
    }
    private loadSampaio(): Promise<any> {
        return import('src/app/company/sampaio/main/sampaio-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.SampaioMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }

    private loadRealExpresso(): Promise<any> {
        return import('src/app/company/real-rapido/main/real-rapido-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.RealRapidoMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }
    private loadUtil(): Promise<any> {
        return import('src/app/company/util/main/util-main.module')
            .then(mod => this.compiler.compileModuleAsync(mod.UtilMainModule))
            .then(mod => {
                this.company = mod.create(this.injector).instance;
            });
    }

}