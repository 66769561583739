import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
// import { renderFlagCheckIfStmt } from "@angular/compiler/src/render3/view/template";
import { from, Observable, of, throwError, timer } from "rxjs";
import { mergeMap } from "rxjs/internal/operators/mergeMap";
import { catchError, delay, flatMap, map, retryWhen } from 'rxjs/operators';
import { Serializer } from "../models";
import { AuthService } from "./auth.service";
import { ConfigService } from "./config.service";

export class BaseApi {

    public constructor(
        private baseUrl: string,
        private controllerName: string,
        private httpClient: HttpClient,
        private configService: ConfigService,
        private idUser?: number,
        private authService?: AuthService,
        private isGateway: boolean = false
    ) {
        this.idUser = this.configService.company.idUser;
    }

    private buildUri(resource: string): string {
        if (this.isGateway) {
            return `${this.baseUrl}/app/${this.controllerName}/${resource}`;
        } else {
            return `${this.baseUrl}/api/${this.controllerName}/${resource}`;
        }
    }

    private getHeaders(): Observable<HttpHeaders> {
        let result = new HttpHeaders();
        return from(this.authService.getToken().then(authToken => {

            if (this.isGateway) {
                result = result.append('Authorization', `Bearer ${authToken}`);
            }

            result = result.append('content-type', 'application/json');
            result = result.append('IdentifierWorkstation', this.configService.company.identifierWorkstation);
            result = result.append('ProductId', this.configService.config.productId);
            result = result.append('IdUser', this.idUser.toString());
            return result;
        }));
    }



    public get<T = any>(resource: string, serializer?: Serializer): Observable<T> {
        var ret = this.getHeaders().pipe(mergeMap((headers) => {
            const uri = this.buildUri(resource);
            return this.httpClient
                .get<T>(
                    uri,
                    {
                        headers: headers
                    });
        })).pipe(catchError(error => {

            if (!this.checkAttempt(resource))
                return throwError(error);

            if (error.status == 401) {
                return of(null).pipe(
                    delay(1000),
                    mergeMap(() => from(this.authService.getToken(true))),
                    mergeMap(() => this.get(resource, serializer))
                );
            }
            else if (error.status == 500) {
                var msg = error.error != null && error.error != '' ? error.error : error._body;
                if (msg != undefined && msg != '') {
                    if (msg.indexOf('1AE') >= 0) {
                        return of(null).pipe(
                            delay(1000),
                            mergeMap(() => from(this.authService.getToken(true))),
                            mergeMap(() => this.get(resource, serializer))
                        );
                    }
                }
                if (error.statusText == "1AE") {
                    return of(null).pipe(
                        delay(1000),
                        mergeMap(() => from(this.authService.getToken(true))),
                        mergeMap(() => this.get(resource, serializer))
                    );
                }

            }

            return throwError(error);
        }));

        if (this.isGateway) {
            return ret;
        }

        return ret.pipe(map(x => serializer.fromJson((x as any).Content)));
    }

    public getAsCollection<T = any[]>(resource: string, serializer?: Serializer): Observable<T> {
        var ret = this.getHeaders().pipe(mergeMap((headers) => {
            const uri = this.buildUri(resource);
            return this.httpClient
                .get<T>(
                    uri,
                    {
                        headers: headers
                    });
        })).pipe(catchError(error => {

            if (!this.checkAttempt(resource))
                return throwError(error);

            if (error.status == 401) {
                return of(null).pipe(
                    delay(1000),
                    mergeMap(() => from(this.authService.getToken(true))),
                    mergeMap(() => this.getAsCollection(resource, serializer))
                );
            }
            else if (error.status == 500) {
                var msg = error.error != null && error.error != '' ? error.error : error._body;
                if (msg != undefined && msg != '') {
                    if (msg.indexOf('1AE') >= 0) {
                        return of(null).pipe(
                            delay(1000),
                            mergeMap(() => from(this.authService.getToken(true))),
                            mergeMap(() => this.getAsCollection(resource, serializer))
                        );
                    }
                }
                if (error.statusText == "1AE") {
                    return of(null).pipe(
                        delay(1000),
                        mergeMap(() => from(this.authService.getToken(true))),
                        mergeMap(() => this.getAsCollection(resource, serializer))
                    );
                }

            }

            return throwError(error);
        }));;

        if (this.isGateway) {
            return ret as any;
        }

        return ret.pipe(map(x => serializer.fromJsonCollection((x as any).Content))) as any;
    }

    public post<T = any>(resource: string, item: any, serializer?: Serializer): Observable<T> {
        var ret = this.getHeaders().pipe(mergeMap((headers) => {
            const uri = this.buildUri(resource);
            return this.httpClient
                .post<T>(
                    uri,
                    item,
                    {
                        headers: headers
                    });
        }))
            .pipe(catchError(error => {

                if (!this.checkAttempt(resource))
                    return throwError(error);

                if (error.status == 401) {
                    return of(null).pipe(
                        delay(1000),
                        mergeMap(() => from(this.authService.getToken(true))),
                        mergeMap(() => this.post(resource, item, serializer))
                    );
                }
                else if (error.status == 500) {
                    var msg = error.error != null && error.error != '' ? error.error : error._body;
                    if (msg != undefined && msg != '') {
                        if (msg.indexOf('1AE') >= 0) {
                            return of(null).pipe(
                                delay(1000),
                                mergeMap(() => from(this.authService.getToken(true))),
                                mergeMap(() => this.post(resource, item, serializer))
                            );
                        }
                    }
                    if (error.statusText == "1AE") {
                        return of(null).pipe(
                            delay(1000),
                            mergeMap(() => from(this.authService.getToken(true))),
                            mergeMap(() => this.post(resource, item, serializer))
                        );
                    }

                }

                return throwError(error);
            }));


        if (this.isGateway) {
            return ret;
        }

        return ret.pipe(map(x => serializer.fromJson((x as any).Content)));
    }

    public postAsCollection<T = any[]>(resource: string, item: any, serializer?: Serializer): Observable<T> {
        var ret = this.getHeaders().pipe(mergeMap((headers) => {
            const uri = this.buildUri(resource);
            return this.httpClient
                .post<T>(
                    uri,
                    item,
                    {
                        headers: headers
                    });
        })).pipe(catchError(error => {

            if (!this.checkAttempt(resource))
                return throwError(error);

            if (error.status == 401) {
                return of(null).pipe(
                    delay(1000),
                    mergeMap(() => from(this.authService.getToken(true))),
                    mergeMap(() => this.postAsCollection(resource, item, serializer))
                );
            }
            else if (error.status == 500) {
                var msg = error.error != null && error.error != '' ? error.error : error._body;
                if (msg != undefined && msg != '') {
                    if (msg.indexOf('1AE') >= 0) {
                        return of(null).pipe(
                            delay(1000),
                            mergeMap(() => from(this.authService.getToken(true))),
                            mergeMap(() => this.postAsCollection(resource, item, serializer))
                        );
                    }
                }
                if (error.statusText == "1AE") {
                    return of(null).pipe(
                        delay(1000),
                        mergeMap(() => from(this.authService.getToken(true))),
                        mergeMap(() => this.postAsCollection(resource, item, serializer))
                    );
                }
            }

            return throwError(error);
        }));

        if (this.isGateway) {
            return ret as any;
        }

        return ret.pipe(map(x => serializer.fromJsonCollection((x as any).Content))) as any;
    }

    private countAttemptLogin: { [key: string]: number } = {};
    private checkAttempt(resource: string,): boolean {
        this.countAttemptLogin[resource] = this.countAttemptLogin[resource] && this.countAttemptLogin[resource] < 5 ? this.countAttemptLogin[resource] + 1 : 1;
        return this.countAttemptLogin[resource] && this.countAttemptLogin[resource] < 5;
    }

}