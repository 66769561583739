<a class="main-header-link" (click)="openSubMenu()">
    {{ 'customerMenu.headerLoggedIn' | translate }}, {{ userName }}
    <i class="fas fa-sort-down main-header-link-icon"></i>
    <div #submenu class="main-header-sub-menu-wrapper">
        <div class="main-header-sub-menu-side-content">
            <h3 class="side-content-title">{{'customerMenu.menuTitleLoggedIn'| translate}}</h3>
            <!--<h4 class="side-content-sub-title">{{ 'customerMenu.subTitle' | translate }}</h4>-->
            <h4 class="side-content-sub-title">Com o seu login você tem<br />acesso aos seus últimos<br />pedidos, facilidades ao<br />comprar e conteúdo exclusivo.</h4>
            <a class="side-content-logout-link" (click)="logout()">{{ 'customerMenu.logoutLabel' | translate }}</a>
        </div>
        <div class="main-header-sub-menu-links">
            <a class="main-header-sub-menu-link" (click)="redirect('/meuspedidos/busca', $event)">{{'customerMenu.searchOrder' | translate}}<i class="fal fa-ticket fa-fw icon"></i></a>
            <span class="menu-split-item"></span>
            <a class="main-header-sub-menu-link" (click)="redirect('/minhaconta', $event)">{{'customerMenu.header' | translate}}<i class="fal fa-user fa-fw icon"></i></a>
            <a class="main-header-sub-menu-link" (click)="redirect('/minhaconta/pedidos', $event)">{{'customerMenu.ordersLabel' | translate}}<i class="fal fa-ticket-alt fa-fw icon"></i></a>
            <a class="main-header-sub-menu-link" [hidden]="config.idTenant == 2 || config.idTenant == 6 || config.idTenant == 7 || config.idTenant == 8 || config.idTenant == 9 || config.idTenant == 10 || config.idTenant == 12 || config.idTenant == 13 || config.idTenant == 14 || config.idTenant == 15" (click)="redirect('/gratuidade', $event)">{{'customerMenu.freeBookingLabel' | translate}}<i class="fal fa-user-tag fa-fw icon"></i></a>
            <a class="main-header-sub-menu-link" [hidden]="(config.idTenant != 2 && config.idTenant != 9) || !config.showVoucher" (click)="redirect('/voucher', $event)">{{'customerMenu.voucherBookingLabel' | translate}}<i class="fal fa-user-tag fa-fw icon"></i></a>
        </div>
    </div>
</a>
