import { BookingTicket, Trip, TripSearchData, OreonsDatetime, OutletTripRange } from "../../models";
import { DateHelper } from "../../helpers";
import { AddPaymentInfo } from "./add-payment-info";

export class PurchaseEvent {
    event: string = 'purchase';
    ecommerce: any;
    uniqueEventId: string;
    user_id: number;

    constructor(
        paymentInfo: AddPaymentInfo,
        trips: Trip[],
        tripSearchData: TripSearchData,
        tickets: BookingTicket[],
        recordLocator: string,
        user_id: number,
        discount: number,
        backTrip: Trip | null
    ) {
        const items = this.createItems(trips, tripSearchData, tickets, backTrip);
        const { totalTax, totalServiceTax, totalValue } = this.calculateTotals(tickets);
        const coupon = tripSearchData.promoCode ? tripSearchData.promoCode : "";

        this.ecommerce = {
            discount: discount,
            items: items,
            tax: roundNumber(totalTax),
            tax_service: roundNumber(totalServiceTax),
            transaction_id: recordLocator,
            transactionId: recordLocator,
            currency: 'BRL',
            coupon: coupon,
            payment_type: paymentInfo.payment_type,
            value: roundNumber(totalValue)
        };

        this.user_id = user_id;
        this.uniqueEventId = recordLocator;
    }

    private createItems(trips: Trip[], tripSearchData: TripSearchData, tickets: BookingTicket[], backTrip: Trip | null): any[] {
        const items: any[] = [];

        if (trips.length > 0) {
            const trip = trips[0];
            const halfIndex = Math.ceil(tickets.length / 2);
            const outboundTickets = tickets.slice(0, halfIndex);
            const returnTickets = tickets.slice(halfIndex);

            items.push(this.createTravelItem(trip, outboundTickets, false));

            if (backTrip) {
                items.push(this.createTravelItem(backTrip, returnTickets, true));
            }
        }

        return items;
    }

    private createTravelItem(trip: Trip, tickets: BookingTicket[], isReturnTrip: boolean): any {
        let totalValue = 0;
        let totalServiceTax = 0;

        tickets.forEach(ticket => {
            ticket.coupons.forEach(coupon => {
                if (coupon.price?.total?.currencyValue != null) {
                    totalValue += coupon.price.total.currencyValue;
                }
                if (coupon.price?.convenienceFee?.currencyValue != null) {
                    totalServiceTax += coupon.price.convenienceFee.currencyValue;
                }
            });
        });

        const itemName = `${trip.departureLocationName} <> ${trip.arrivalLocationName}`;
        const productId = `GUA-${trip.idDepartureLocation}-${trip.idArrivalLocation}`;
        const hasConnections = trip.routes.length > 1 ? 'sim' : 'nao';
        const price = trip.price;
        const departureDateTime = this.convertToDate(trip.departureDateTime);
        const arrivalDateTime = this.convertToDate(trip.arrivalDateTime);
        const tripRange = new OutletTripRange(departureDateTime, arrivalDateTime, trip);
        const tripTime = tripRange.startingSaleDateTime;
        const returnDate = isReturnTrip
            ? DateHelper.convertToFormattedDate(this.convertToDate(trip.departureDateTime), "MM/DD/YYYY", "YYYY-MM-DD")
            : '';
        const departureDate = isReturnTrip ? "" : DateHelper.convertToFormattedDate(
            this.convertToDate(trip.departureDateTime), "MM/DD/YYYY", "YYYY-MM-DD");

        return {
            originCity: trip.departureLocationName,
            destinationCity: trip.arrivalLocationName,
            departureDate: departureDate,
            returnDate: returnDate,
            travelClass: trip.classOfServiceName,
            quantity: tickets.length,
            item_name: itemName,
            item_id: productId,
            price: roundNumber(price),
            company: trip.companyName,
            hasConnections: hasConnections,
            trip_time: tripTime
        };
    }

    private calculateTotals(tickets: BookingTicket[]): { totalTax: number, totalServiceTax: number, totalValue: number } {
        let totalTax = 0;
        let totalServiceTax = 0;
        let totalValue = 0;

        tickets.forEach(ticket => {
            ticket.coupons.forEach(coupon => {
                if (coupon.price?.boardingFee?.currencyValue != null) {
                    totalTax += coupon.price.boardingFee.currencyValue;
                }
                if (coupon.price?.mandatoryInsurance?.currencyValue != null) {
                    totalTax += coupon.price.mandatoryInsurance.currencyValue;
                }
                if (coupon.price?.toll?.currencyValue != null) {
                    totalTax += coupon.price.toll.currencyValue;
                }
                if (coupon.price?.convenienceFee?.currencyValue != null) {
                    totalServiceTax += coupon.price.convenienceFee.currencyValue;
                }
                if (coupon.price?.total?.currencyValue != null) {
                    totalValue += coupon.price.total.currencyValue;
                }
            });
        });

        return { totalTax, totalServiceTax, totalValue };
    }

    private convertToDate(oreonsDateTime: OreonsDatetime): Date {
        return new Date(
            oreonsDateTime.year,
            oreonsDateTime.month - 1,
            oreonsDateTime.day,
            oreonsDateTime.hour,
            oreonsDateTime.minute
        );
    }
}

function roundNumber(value: number): number {
    if (!value) {
        return null;
    }

    return parseFloat(value.toFixed(2));
}