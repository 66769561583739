import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { timer } from 'rxjs';
import { Site } from 'src/app/main/models';
import { ConfigService } from 'src/app/main/services';

type UnificationCompaniesParam = {
  labelName: string;
  logoSrc: Array<string>;  
  logoAlt: string;
}

@Component({
  selector: 'app-unification-animation',
  templateUrl: './unification-animation.component.html',
  styleUrls: ['./unification-animation.component.scss']
})

export class UnificationAnimationComponent implements OnInit {
  redirectedCompanyProperties = {} as UnificationCompaniesParam;
  isClosed: boolean = true;
  
  constructor(
    private route: ActivatedRoute,
    private configService: ConfigService
  ) { }

  get siteConfig(){
    return this.configService.config;
  }

  ngOnInit(): void {
    this.getRouteCompanyParam();    
  }

  getRouteCompanyParam (): void {
    this.route.queryParams.subscribe({
      next: (param) => {
        if (param['redirectedBy']) {
          this.isClosed = false;

          const cdnUri = `${this.siteConfig.cdnUrl}ecommerce/imgs/common-guanabara/unificacao/`;
          
          this.redirectedCompanyProperties = {
            labelName: param['redirectedBy'],
            logoSrc: [
              `${cdnUri + param['redirectedBy']}-unificacao.webp`, 
              `${cdnUri + param['redirectedBy']}-unificacao.png`
            ],
            logoAlt: `Logo da empresa ${param['redirectedBy']}`
          };
  
          timer(5000).subscribe(() => { this.isClosed = true });          
        }
      }
    })
  }
}
