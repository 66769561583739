<div class="main-layer" *ngIf="!isClosed">
  <div class="description-text">
    <h4>Agora a <strong class="text-highlight">{{ redirectedCompanyProperties.labelName }}</strong> é <strong class="text-highlight">guanabara</strong></h4>
  </div>
  <div class="load-bar">
    <div class="load-bar__progress"></div>
  </div>
  <div class="unification-logos">
    <div class="unification-logos__guanabara">
      <picture>
        <source type="image/webp" srcset="{{siteConfig.cdnUrl}}ecommerce/imgs/common-guanabara/unificacao/guanabara-logo.webp" />
        <img [lazyLoad]="'{{siteConfig.cdnUrl}}ecommerce/imgs/common-guanabara/unificacao/guanabara-logo.png'" />
      </picture>
    </div>
    <div class="unification-logos__company">
      <picture>
        <source type="image/webp" srcset="{{redirectedCompanyProperties.logoSrc[0]}}" />
        <img alt="{{redirectedCompanyProperties.logoAlt}}" [lazyLoad]="'{{redirectedCompanyProperties.logoSrc[1]}}'" />
      </picture>
    </div>
  </div>
</div>
