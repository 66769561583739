<style>
   
    .map {
        position: relative !important;
        display: flex !important;
        width: 70%;
        height: 70px;
        left: 23%;
    }

    .coordinatesFound {
        position: relative !important;
        display: flex !important;
        width: 70%;
        left: 23%;
    }

    .map{
        cursor: pointer;
    }
</style>
<div id="map-departure" class="map" onclick="document.querySelector('#modal-map-large').style.display='flex'">
</div>
<div *ngIf="!coordinatesFound" class="coordinatesFound" style="color:rgba(112, 112, 112, 1); font-size:10px;">
    Ops! O mapa e endereço para esse ponto não está disponível no momento
</div>