import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SalesforceRequest } from "../models";
import { Observable, catchError, throwError } from "rxjs";
import { ConfigService } from "./config.service";


@Injectable({
    providedIn: 'root'
})

export class SalesforceService {
    
    private apiUrl: string = `${this.configService.config.productsCatalogApiDomain}api/GipsyyWeb/Checkout/Salesforce/Products`;

    constructor(
        private http: HttpClient,
        private configService: ConfigService,
    ) { }

    public sendCollection(req: SalesforceRequest): Observable<SalesforceRequest> {
        return this.http.post<SalesforceRequest>(this.apiUrl, req)
            .pipe(
                catchError(this.handleError)
            );
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            console.error('Ocorreu um erro:', error.error);
        } else {
            console.error(
                `Erro retornado da API, de status ${error.status}. Corpo de erro: `, error.error);
        }
        return throwError(() => new Error('Ocorreu um erro. Por favor, tente novamente mais tarde.'));
    }
}