import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { interval, Subscription } from 'rxjs';

@Component({
  selector: 'app-stopwatch-guanabara',
  templateUrl: './stopwatch.html',
})
export class StopwatchGuanabaraComponent implements OnInit, OnDestroy {
  private countdownSubscription: Subscription;
  public countdownText: string;
  public showComponent: boolean = true;

  isMobile: boolean;

  constructor() {
    this.isMobile = window.innerWidth <= 768;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.isMobile = window.innerWidth <= 768;
  }

  ngOnInit() {
    const blackFridayDate = new Date('2023-11-26T23:59:00');

    this.countdownSubscription = interval(1000).subscribe(() => {
      const remainingTime = blackFridayDate.getTime() - new Date().getTime();
      if (remainingTime > 0) {
        const days = Math.floor(remainingTime / (1000 * 60 * 60 * 24));
        const hours = Math.floor((remainingTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);

        this.countdownText = `${days}D:${hours}H:${minutes}M:${seconds}S `;
      } else {
        this.showComponent = false;
      }
    });
  }

  ngOnDestroy() {
    if (this.countdownSubscription) {
      this.countdownSubscription.unsubscribe();
    }
  }
}
//